

/* You can add global styles to this file, and also import other style files */
html {
  font-size: 14px;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.main-card {
  padding: 10px;
}

.flex-container {
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
}

@import "primeflex/primeflex.scss";
@import "primeicons/primeicons.css";